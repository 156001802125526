<template>
 <div class="w-100">
   <table class="table table-bordered table-sm" v-if="result">
     <tr>
       <th>Hardware Number</th>
       <th>Software Version</th>
     </tr>
     <tr>
       <td>{{result.hardware_number}}</td>
       <td>{{result.software_version}}</td>
     </tr>
   </table>
 </div>
</template>
<script>

import {mapMutations} from "vuex";
import {reports_array_dsg} from "@/constants";

export default {
  name: "_FilePreOrders",
  props: {
    result: null,
  },
  data: () => ({
    formatData: {
      hardware_number: null,
      software_version: null
    }
  }),
}
</script>
<style scoped lang="scss"></style>
