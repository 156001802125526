<template>
  <div class="w-100">
    <div class="w-100">
      <b-card class="table-responsive">
        <b-table bordered small hover selectable select-mode="single" :items="arrayOrders" :fields="column" @row-clicked="rowClicked">
          <template #cell(result)="{item}">
            <span v-if="item.result" class="font-weight-bold" style="cursor: pointer">See more...</span>
          </template>
          <template #cell(reported_error)="{item}">
            <span v-if="typeof item.reported_error === 'string'">{{item.reported_error}}</span>
            <span v-else>NO</span>
          </template>
          <template #row-details="{item}">
            <file-pre-orders-details :result="item.result"></file-pre-orders-details>
          </template>
        </b-table>
      </b-card>
    </div>
  </div>
</template>

<script>
import {get} from "@/services/api";
import FilePreOrdersDetails from "./_FilePreOrders.vue"
export default {
  name: "FilesPreOrder",
  components:{FilePreOrdersDetails},
  mounted() {
    this.getData()
  },
  data: ()=>({
    arrayOrders: [],
    column: [
      {key: '_id', label: 'ID',sortable: true},
      {key: 'VIN', label: 'VIN',sortable: true},
      {key: 'reported_error', label: 'Has Error?',sortable: true},
      {key: 'result', label: 'Result',sortable: true},
    ]
  }),
  methods: {
    async getData(){
      const {data} = await get(`file-processor/get-cmd-pre-orders`,null, true)
      this.arrayOrders = data
    },
    rowClicked(e) {
      e._showDetails = !e._showDetails
    },
  }
}
</script>

<style scoped>

</style>
